<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Settings</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Banner Text</th>
                  <td>
                    <tiptap v-model="banner_text" class="mb-8" />

                    <v-btn
                      color="accent"
                      :loading="banner_text_loading"
                      @click="updateSetting('banner_text', 'text')"
                      >Save</v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  props: {
    appColor: {
      type: String,
    },
  },
  components: {
    Tiptap,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Settings",
          disabled: true,
        },
      ],

      banner_text: null,
      banner_text_loading: false,
    };
  },

  computed: {
    settings() {
      return this.$store.getters["petroshops/settingsStore/all"];
    },
  },

  mounted() {
    if (this.settings.some((s) => s.name === "banner_text")) {
      const setting = this.settings.find((s) => s.name === "banner_text");

      this.banner_text = setting.content;
    }
  },

  methods: {
    updateSetting(name, type) {
      const appId = this.$route.params.id;
      this[name + "_loading"] = true;

      const content =
        type === "boolean" ? (this[name] ? "1" : "0") : this[name];

      this.$store
        .dispatch("petroshops/settingsStore/updateSettings", {
          appId,
          fields: {
            name,
            content,
          },
        })
        .then(() => {
          this[name + "_loading"] = false;

          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Settings Updated Successfully!",
            color: "success",
          });
        });
    },
  },
};
</script>
